import { request } from "./network";
import da from "element-ui/src/locale/lang/da";

// 获取试列表
export function stuCodes(params) {
    return request({
        method: "get",
        url: "/stuCodes",
        params,
    });
}
