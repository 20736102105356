<template>
    <div class="container">
        <div class="qr-tit">模拟测试二维码</div>
        <div class="qr-box">
            <div class="qr-item" v-for="(item, index) in qrCsList" :key="index">
                <div class="qr-item-tit">{{item.subjectName}}</div>
                <div class="qrcode" ref="qrCsUrl"></div>
                <div class="qr-item-code">{{item.num}}</div>
            </div>
        </div>
        <div class="qr-tit">正式考试二维码</div>
        <div class="qr-box">
            <div class="qr-item" v-for="(item, index) in qrCodeList" :key="index">
                <div class="qr-item-tit">{{item.subjectName}}</div>
                <div class="qrcode" ref="qrCodeUrl"></div>
                <div class="qr-item-code">{{item.num}}</div>
            </div>
        </div>
        <div class="hint-box">
            <div class="hint-tit">注意:</div>
            <div class="hint-txt">
                1、模拟测试二维码仅限考生本人用于4月20日的模拟测试，正式考试二维码仅限考生本人用于4月21日的正式考试。如考生将二维码页面或相关信息泄露或分享给其他人，按违规处理。
            </div>
            <div class="hint-txt">
                2、考生可以将本网页进行打印，方便在模拟测试和正式考试中使用。
            </div>
            <div class="hint-txt">
                3、如无打印设备，可以将科目二维码下方编号按照科目抄写在空白的纸上，在模拟测试和正式考试中输入科目二维码下方编号进行开考与交卷的操作。
            </div>
            <div class="hint-txt">
                4、打印二维码或抄写编号的纸张除科目二维码，二维码编号外，不得出现其他任何内容。监考老师会对纸张进行检查。如纸张检查不合格，按违规处理。
            </div>
        </div>
    </div>
</template>

<script>
    import QRCode from "qrcodejs2";
    import {stuCodes} from "r/qrCode/lists.js";

    export default {
        name: "List",
        data() {
            return {
                qrCodeList: [],// 正式
                qrCsList: [] // 测试
            }
        },
        mounted() {
            this.getStuCodes();
        },
        methods: {
            getStuCodes() {
                stuCodes({
                    zkzNum: window.localStorage.getItem('qrCode-idCard')
                })
                    .then(res => {
                        this.qrCodeList = res.data.false.filter(item=>{
                            item['num'] = item.code.slice(item.code.length-10,item.code.length);
                            return item;
                        });
                        this.qrCsList = res.data.true.filter(item=>{
                            item['num'] = item.code.slice(item.code.length-10,item.code.length);
                            return item;
                        });
                        this.getQrCode();
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            getQrCode() {
                this.$nextTick(() => {
                    this.qrCodeList.forEach((item, index) => {
                        this.createQrCode(item.code, index, 'qrCodeUrl')
                    })
                    this.qrCsList.forEach((item, index) => {
                        this.createQrCode(item.code, index, 'qrCsUrl')
                    })
                }, 500)
            },
            createQrCode(url, index, refName) {
                this.$refs[refName][index].innerHTML = "";
                new QRCode(this.$refs[refName][index], {
                    text: url, // 需要转换为二维码的内容
                    width: 90,
                    height: 90,
                    colorDark: "#000000",
                    colorLight: "#ffffff",
                    correctLevel: QRCode.CorrectLevel.H,
                });
            },
        }
    }
</script>

<style scoped lang="scss">
    .container {
        width: 100%;
        max-width: 1300px;
        min-width: 824px;
        margin: 0 auto;
        padding: 0 100px;
        font-family: 微软雅黑;

        .qr-tit {
            font-size: 16px;
            font-weight: bold;
            text-align: center;
            line-height: 26px;
            margin: 30px 0 20px;
        }

        .qr-box {
            display: flex;
            flex-font: row;

            .qr-item {
                flex: 1;

                > div {
                    display: flex;
                    flex-font: row;
                    align-items: center;
                    justify-content: center;
                    margin: 10px 0;
                }

                .qr-item-tit {
                    font-size: 14px;
                    font-weight: bold;
                    line-height: 20px;
                    text-align: center;
                }

                .qr-item-code {
                    font-size: 14px;
                    line-height: 20px;
                    text-align: center;
                }

                .qrcode img {
                    display: inline-block !important;
                    margin: 0 auto !important;
                }
            }
        }

        .hint-box {
            font-size: 12px;
            font-weight: bold;
            line-height: 20px;
            text-align: left;

        }
    }
</style>
